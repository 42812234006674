import oldregimedeductions from "./oldregime";
import oldregimetax from "./oldtaxslabprocessor";
import newtaxslabprocessor23 from "./newtaxslabprocessor23";
import newtaxslabprocessor24 from "./newtaxslabprocessor24.js";
import newdeduct from "./newregime";

const oldregimeoutput = (
  incomebeforedeductionold,
  inputoneold,
  inputtwoold,
  surchargeold,
  longtermgainold
) => {
  
  const deductionoldregime = oldregimedeductions(inputoneold, inputtwoold);

  const outputoldtaxregimetaxableincome =
    incomebeforedeductionold - deductionoldregime;



  const outputoldtaxregime = oldregimetax(
    surchargeold,
    outputoldtaxregimetaxableincome,
    longtermgainold
  );

  return outputoldtaxregime;
};

const taxmain = (taxregime, inputone, inputtwo) => {
  let outputnewtaxregime;
  let deductionnewregime;
  let outputnewtaxregimetaxableincome;

 
  const incomebeforededuction =
    inputone.basic +
    inputone.hra +
    inputone.specialAllowance +
    inputone.otherAllowance +
    inputone.otherComponent +
    inputone.epfself +
    inputone.epfcompany +
    inputone.bonus +
    inputone.shortTermCapitalGains +
    inputone.da+
    inputone.incomeothersource;

 
  const surcharge =
    inputone.basic +
    inputone.hra +
    inputone.specialAllowance +
    inputone.otherAllowance +
    inputone.otherComponent +
    inputone.epfself +
    inputone.epfcompany +
    inputone.bonus +
    inputone.shortTermCapitalGains +
    inputone.longTermCapitalGains +
    inputone.da +
    inputone.incomeothersource;

 
  const longTermCapitalGains = inputone.longTermCapitalGains;


  const outputoldtaxregime = oldregimeoutput(
    incomebeforededuction,
    inputone,
    inputtwo,
    surcharge,
    longTermCapitalGains
  );

  //new tax regime

  //newtaxremine 23
  if (taxregime === "FY 2022-2023 (01/04/2022 to 31/03/2023)") {
    outputnewtaxregime = newtaxslabprocessor23(
      surcharge,
      incomebeforededuction,
      longTermCapitalGains
    );

    
    //newtaxregime24
  } else if (taxregime === "FY2023-2024 (01/04/2023 to 31/03/2024)") {
    deductionnewregime = newdeduct(inputone, inputtwo);
    
    outputnewtaxregimetaxableincome =
      incomebeforededuction - deductionnewregime;

   
    outputnewtaxregime = newtaxslabprocessor24(
      surcharge,
      outputnewtaxregimetaxableincome,
      longTermCapitalGains
    );

    
  }

  const monthlyposttaxold =
    (inputone.ctc / 12) -
    (outputoldtaxregime / 12)-
    (inputone.epfcompany / 12) -
   ( inputone.epfself/12) -
   ( inputtwo.employerNpsContribution / 12);
  

  let monthlyctc = inputone.ctc / 12 ;
  let outputmonthly = outputnewtaxregime / 12 ;
  let epfcompanymonthly = inputone.epfcompany / 12;
  let employerNpsContributionmonthly =  inputtwo.employerNpsContribution / 12;
  let epfselfmonthly = inputone.epfself/12 ;
  const monthlyposttaxnew =
  monthlyctc - outputmonthly - epfcompanymonthly -employerNpsContributionmonthly - epfselfmonthly
    

 
  const result = {};
  result.outputnewtaxregime = Math.floor(outputnewtaxregime);
  result.outputoldtaxregime = Math.floor(outputoldtaxregime);
  result.monthlyposttaxold = Math.floor(monthlyposttaxold);
  result.monthlyposttaxnew = Math.floor(monthlyposttaxnew);

  return result;
};

export default taxmain;
