const calculateOutput1 = (
  isRentedAccommodation,
  isHomeLoan,
  isHomeLoanAndRent,
  isSameCity,
  totalHouseRentPaid,
  basic,
  hra
) => {
  if (isRentedAccommodation) {
    if (isHomeLoan && isHomeLoanAndRent && !isSameCity) {
      const hraExemption = Math.min(
        totalHouseRentPaid - 0.1 * basic,
        0.5 * basic
      );
      return Math.min(hraExemption, hra);
    } else if (!isHomeLoan) {
      const hraExemption = Math.min(
        totalHouseRentPaid - 0.1 * basic,
        0.5 * basic
      );
      return Math.min(hraExemption, hra);
    } else {
      return 0;
    }
  }
  return 0;
};

const calculateOutput2 = (
  taxSavingsInvestments,
  homeLoanPrincipal,
  epfself,
  educationPrincipal,
  isHomeLoan
) => {
  if (isHomeLoan) {
    return Math.min(
      taxSavingsInvestments + homeLoanPrincipal + epfself + educationPrincipal,
      150000
    );
  } else {
    return Math.min(
      taxSavingsInvestments + epfself + educationPrincipal,
      150000
    );
  }
};

/* return Math.min(taxSavingsInvestments + homeLoanPrincipal + epfself _+ educationprinciple, 150000); */

const calculateOutput3 = (homeLoanInterest, isHomeLoan) => {
  if (isHomeLoan) {
    return Math.min(homeLoanInterest, 200000);
  } else {
    return 0;
  }
};

const calculateOutput4 = (personalNpsContribution) => {
  return Math.min(personalNpsContribution, 50000);
};

const calculateOutput5 = (inputone, inputtwo) => {
  const { basic } = inputone;
  const { isEmployerNpsContribution, employerNpsContribution, isGovtemployee } =
    inputtwo;

  if (isGovtemployee === false && isEmployerNpsContribution === true) {
    return Math.min(0.1 * basic, 150000, employerNpsContribution);
  } else if (isGovtemployee === true && isEmployerNpsContribution === true) {
    const { da } = inputone;
    return Math.min(0.14 * (basic + da), 150000, employerNpsContribution);
  } else {
    return 0;
  }
};
/*if govtemployee == 0  

  return Math.min(0.1 * basic, 150000, employerNpsContribution);

else govtemployee = 1

  return Math.min(0.14 * (basic +DA) , 150000, employerNpsContribution);

*/

const calculateOutput6 = (healthInsuranceSelf, medicalTestSelf) => {
  return Math.min(healthInsuranceSelf + medicalTestSelf, 25000);
};

const calculateOutput7 = (
  healthInsuranceParentsContributionAbove60,
  medicalTestParents,
  isHealthInsuranceParentsAbove60,
  healthInsuranceParentsContributionBelow60
) => {
  if (isHealthInsuranceParentsAbove60) {
    return Math.min(
      healthInsuranceParentsContributionAbove60 + medicalTestParents,
      50000
    );
  } else {
    return Math.min(
      healthInsuranceParentsContributionBelow60 + medicalTestParents,
      25000
    );
  }
};

/* Output 8 = interestcomponent 

*/

const oldregimedeductions = (inputone, inputtwo) => {

  const output1 = calculateOutput1(
    inputtwo.isRentedAccommodation,
    inputtwo.isHomeLoan,
    inputtwo.isHomeLoanAndRent,
    inputtwo.isSameCity,
    inputtwo.totalHouseRentPaid,
    inputone.basic,
    inputone.hra
  );

  const output2 = calculateOutput2(
    inputtwo.taxSavingsInvestments,
    inputtwo.homeLoanPrincipal,
    inputone.epfself,
    inputtwo.educationPrincipal,
    inputtwo.isHomeLoan
  );
  const output3 = calculateOutput3(
    inputtwo.homeLoanInterest,
    inputtwo.isHomeLoan
  );
  const output4 = calculateOutput4(inputtwo.personalNpsContribution);
  const output5 = calculateOutput5(inputone, inputtwo);
  const output6 = calculateOutput6(
    inputtwo.healthInsuranceSelf,
    inputtwo.medicalTestSelf
  );
  const output7 = calculateOutput7(
    inputtwo.healthInsuranceParentsContributionAbove60,
    inputtwo.medicalTestParents,
    inputtwo.isHealthInsuranceParentsAbove60,
    inputtwo.healthInsuranceParentsContributionBelow60
  );


  return (
    output1 +
    output2 +
    output3 +
    output4 +
    output5 +
    output6 +
    output7 +
    inputtwo.educationInterest +
    inputone.epfcompany
  );
};

export default oldregimedeductions;

/*
 

  isSameCity
  isHomeLoanAndRent
  isRentedAccommodation
  isHomeLoan
  


    isRentedAccommodation = true : 


      [isHomeLoan = true and  isHomeLoanAndRent = true and isSameCity = false :
       (Const hraExemption = Math.min(totalHouseRentPaid - (0.1 * basic), 0.5 * basic);
      return Math.min(hraExemption, hra) ) ]

      isHomeLoan = false:
      (Const hraExemption = Math.min(totalHouseRentPaid - (0.1 * basic), 0.5 * basic);
      return Math.min(hraExemption, hra) ) ]
      
        else:
          return(0)

    isRentedAccommodation = false : 

          return(0)





          
  */
