const newdeduct = (inputone, inputtwo) => {
  
  const { basic, da } = inputone;
  const { isEmployerNpsContribution, employerNpsContribution, isGovtemployee } =
    inputtwo;

  if (isGovtemployee === false && isEmployerNpsContribution === true) {
    return Math.min(0.1 * basic, 150000, employerNpsContribution);
  } else if (isGovtemployee === true && isEmployerNpsContribution === true) {
    return Math.min(0.14 * (basic + da), 150000, employerNpsContribution);
  
  } else {
    return 0;
  }
};

/*if govtemployee == 0  

  return Math.min(0.1 * basic, 150000, employerNpsContribution);

else govtemployee = 1

  return Math.min(0.14 * (basic +DA) , 150000, employerNpsContribution);

*/
export default newdeduct;
