import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import MinimalistStepper from "./Instructionsteeper";

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slides, setSlides] = useState([
    {
      heading: "Easy Tax Regime Selection",
      content:
        "Our software takes into account your income data and investment details to calculate the tax outgo in both the old and new tax regimes. By simply filling in the values, you can get an approximation of the tax to be paid, making it effortless to compare and choose the best tax regime for you.",
      gradient: "linear-gradient(45deg, #FFC107, #FF9800)", // Gradient for Slide 1
      image:
        "https://images.pexels.com/photos/2335126/pexels-photo-2335126.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", // Set the path to your desired image for Slide 1
    },
    {
      heading: "Simplification at its Best",
      content:
        "Unlike other complicated tax regime tools, our software is designed with simplicity in mind. We understand that not everyone is well-versed in tax laws and calculations. That's why our tool provides a hassle-free experience, making it accessible to all, especially salaried individuals seeking clarity and guidance.",
      gradient: "linear-gradient(45deg, #3F51B5, #2196F3)", // Gradient for Slide 2
      image:
        "https://images.pexels.com/photos/4628295/pexels-photo-4628295.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", // Set the path to your desired image for Slide 2
    },
    {
      heading: "Time-Saving",
      content:
        "No need to spend hours researching and crunching numbers. Our Tax Regime Selector tool streamlines the process, saving you time and effort. You can quickly assess the tax implications of different regimes, empowering you to make well-informed decisions.",
      gradient: "linear-gradient(45deg, #E91E63, #FF4081)", // Gradient for Slide 3
      image:
        "https://images.pexels.com/photos/1095601/pexels-photo-1095601.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", // Set the path to your desired image for Slide 3
    },
    {
      heading: "Benefits",
      content:
        "Clear Comparison: Our software enables a straightforward comparison between the old and new tax regimes. By presenting the tax outgo in a concise manner, you can easily evaluate the benefits and drawbacks of each regime, ultimately choosing the one that aligns with your financial goals.",
      gradient: "linear-gradient(45deg, #E91E63, #FF4081)", // Gradient for Slide 4
      image:
        "https://images.pexels.com/photos/1563256/pexels-photo-1563256.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", // Set the path to your desired image for Slide 4
    },
  ]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(timer);
  }, [slides.length]);

  const scrollToNextElement = () => {
    const nextElement = document.getElementById("nextElement");
    nextElement.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div style={{ position: "relative", overflow: "hidden", margin: 1 }}>
      <div
        style={{
          display: "flex",
          transition: "transform 2.5s ease-in-out",
          transform: `translateX(-${currentIndex * 100}%)`,
        }}
      >
        {slides.map((slide, index) => (
          <Card
            key={index}
            sx={{
              flex: "0 0 100%",
              height: "100vh",
              maxWidth: "100vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
                zIndex: 1,
                textAlign: "center",
                color: "white",
              }}
            >
              <Box
                sx={{
                  p: 4,
                  bgcolor: "rgba(0, 0, 0, 0.5)",
                  color: "white",
                  textAlign: "left",
                  width: ["80%", "60%", "40%"], // Responsive width values for different screen sizes
                  position: "absolute",
                  left: [
                    "10px",
                    "10px",
                    index === 1 || index === 3 ? null : "10px",
                  ], // Responsive left position
                  right: [
                    "10px",
                    "10px",
                    index === 1 || index === 3 ? "10px" : null,
                  ], // Responsive right position
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
              
                <Typography
                  variant="h4"
                  component="div"
                  color="inherit"
                  sx={{ mb: 2 }}
                >
                  {slide.heading}
                </Typography>
                <Typography variant="body1" component="div">
                  {slide.content}
                </Typography>
              </Box>
            </div>

            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 0,
              }}
            >
              <img
                src={slide.image}
                alt={`Slide ${index + 1}`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>

            <Box
              sx={{
                position: "absolute",
                bottom: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 2,
                bgcolor: "rgba(0, 0, 0, 0.5)",
                color: "white",
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              
              <IconButton
                style={{ color: "white" }}
                onClick={scrollToNextElement}
              >
                <Typography>Get Started</Typography>
                <KeyboardArrowDownIcon fontSize="large" />
              </IconButton>
            </Box>
          </Card>
        ))}
      </div>
      <div id="nextElement" style={{ height: "80vh" }}>
        {/* Next Element */}
        <Box sx={{ flex: "0 0 100%" }}>
          <MinimalistStepper />
        </Box>
      </div>
    </div>
  );
};

export default Carousel;
