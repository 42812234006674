import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export default function DisclaimerCard() {
  const [showDisclaimer, setShowDisclaimer] = useState(true);

  const toggleDisclaimer = () => {
    setShowDisclaimer(!showDisclaimer);
  };

  return (
    <div style={{ marginBottom: "1em" }}>
      <Card
        sx={{
          background: "linear-gradient(135deg, #FF5252 0%, #FF0000 100%)",
          borderRadius: "0.5em",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5" sx={{ color: "white", margin: "0.5em" }}>
            Disclaimer
          </Typography>
          <Button
            onClick={toggleDisclaimer}
            variant="outlined"
            sx={{
              color: "white",
              margin: "0.5em",
              border: "1px solid white",
              padding: 0,
              ":hover": {
                backgroundColor: "transparent",
                color: "white",
                border: "1px solid white",
              },
            }}
          >
            {showDisclaimer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
        </Stack>

        {showDisclaimer && (
          <Card
            sx={{
              background: "linear-gradient(135deg, #FF5252 0%, #FF0000 100%)",
              color: "white",
              margin: "0.5em",
              borderRadius: "0.5em",
            }}
          >
            <CardContent>
              <Typography
                variant="body2"
                sx={{ color: "white", textAlign: "left" }}
              >
                Computing income tax can be daunting, given the intricate
                provisions of the tax act, including various sections,
                deductions, and exemptions. The introduction of a new tax regime
                alongside the existing one adds complexity. We have simplified
                the process, so you can determine your tax liability in each
                regime without navigating complex tax laws. We also provide
                assistance in comparing your tax obligations and post-tax income
                under both regimes, enabling informed decisions. Choose our
                platform to optimize your tax obligations.
              </Typography>
            </CardContent>
          </Card>
        )}
      </Card>
    </div>
  );
}
