import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const AnimatedCard = () => {
  const [showCard, setShowCard] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCard(false);
    }, 20000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showCard && (
        <Card
          sx={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            zIndex: 9999,
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', // Set your desired background gradient
            color: '#ffffff', // Set your desired text color
          }}
        >
          <CardContent>
            <Typography variant="body1">
              Please consider bookmarking this page.
            </Typography>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default AnimatedCard;
