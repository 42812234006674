import "./App.css";
import React from 'react';
import DisclaimerCard from "./Components/Disclaimer";
import MainSteeper from "./Components/MainSteeper";
import Poster from "./Components/Poster";


function App() {

  return (
    <div
      style={{
        backgroundColor: "Snow",
      }}
    >
      <header>
        <Poster></Poster>

        <DisclaimerCard></DisclaimerCard>

        <MainSteeper></MainSteeper>

        {}
      </header>
    </div>
  );
}

export default App;
