import React from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Note from "./Note";
import Bookmark from "./Bookmark";
function Output({ taxregime, taxData }) {
  function createData(name, calculations) {
    return { name, calculations };
  }
  console.log(taxregime, taxData);

  if (!taxData) {
    // Render a loading state or a placeholder
    return <Typography>Loading...</Typography>;
  }

  console.log(taxData.outputnewtaxregime);
  const rows = [
    createData("OLD REGIME (₹) :", null),
    createData("Annual Tax outgo under Old Regime", taxData.outputoldtaxregime),
    createData(
      "Monthly Tax Outgo under Old Regime",
      (taxData.outputoldtaxregime / 12).toFixed(0)
    ),
    createData(
      "Monthly income in hand POST TAX, POST EPF (Self + Employer), POST Employer Contribution to NPS deduction (if applicable)",
      taxData.monthlyposttaxold
    ),
    createData("", null), // Empty row for spacing
    createData("NEW REGIME (₹) :", null),
    createData(
      "Annual Tax payable under NEW REGIME for the financial year selected",
      taxData.outputnewtaxregime
    ),
    createData(
      "Monthly Tax Outgo under New Regime",
      (taxData.outputnewtaxregime / 12).toFixed(0)
    ),
    createData(
      "Monthly income in hand  (NEW REGIME) POST TAX, POST EPF (Self + Employer), POST Employer Contribution to NPS deduction (if applicable)",
      taxData.monthlyposttaxnew
    ),
  ];

  return (
    <Box>
      <Card
        sx={{
          maxWidth: 1,
          height: "auto",
          color: "black",
          border: 1,
          margin: 1,
          backgroundColor: "white",
        }}
      >
        <CardContent
          sx={{
            color: "black",
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ color: "black" }}
          >
            Output
          </Typography>
          <Card
            sx={{
              maxWidth: 1,
              height: "auto",
              color: "black",
              border: 1,
              margin: 1,
              padding: 2,
              backgroundColor: "white",
            }}
          >
            <Typography>Selected Tax Regime: {taxregime}</Typography>
          </Card>
          <TableContainer
            component={Paper}
            sx={{
              marginBottom: 5,
              marginTop: 5,
              border: 1,
              borderColor: "black",
              backgroundColor: "white",
            }}
          >
            <Table
              sx={{
                width: 0.95,
                border: 1,
                margin: 2,
                align: "center",
                borderColor: "black",
                backgroundColor: "white",
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      border: 1,
                      borderColor: "black",
                      color: "black",
                      fontWeight: "bold",
                    }}
                    align="center"
                  >
                    Calculations
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      border: 1,
                      borderColor: "black",
                      color: "black",
                      backgroundColor: index <= 3 ? "pink" : "lightblue",
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        minWidth: 0.5,
                        border: 1,
                        borderColor: "black",
                        color: "black",
                        fontWeight: "bold",
                      }}
                      align="left"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        border: 1,
                        borderColor: "black",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      {row.calculations}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Card
            variant="outlined"
            sx={{
              maxWidth: 1,
              height: "auto",
              color: "black",
              border: 1,
              margin: 2,
              padding: 2,
              backgroundColor: "white",
              boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
              transition: "0.3s",
              borderRadius: "8px",
              "&:hover": {
                boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <Bookmark></Bookmark>
            <CardContent>
              <Typography
                variant="body1"
                component="p"
                sx={{ textAlign: "center" }}
              >
                Reach out to us at{" "}
                <a
                  href="mailto:helpdesk@incometaxregimeselector.in"
                  style={{ color: "dodgerblue", textDecoration: "none" }}
                >
                  helpdesk@incometaxregimeselector.in
                </a>{" "}
                for any clarifications or assistance.
              </Typography>
            </CardContent>
          </Card>

          <Note />
        </CardContent>
      </Card>
    </Box>
  );
}

export default Output;
