import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Card,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/system";

function Form1({ handleChangeone, inputone }) {
  /*

  */

  return (
    <Box
      sx={{
        p: 2,
        border: "1px solid black",
        marginTop: 8,
        borderRadius: "0px",
      }}
    >
      <Typography></Typography>
      <form>
        <Box sx={{ color: "black" }}>
          <Grid item xs={12} spacing={2}>
            <Typography variant="body1" sx={{ color: "black", marginTop:1 }}>
              Enter your Total Cost to Company (CTC) Amount
            </Typography>

            <TextField
            label="CTC"
              type="number"
              onChange={handleChangeone}
              name="ctc"
              value={inputone.ctc.value}
              placeholder="CTC"
              sx={{ marginTop: 1, width: "50%" }}
              error={
                (inputone.ctc.isRequired && !inputone.ctc.value) ||
                (inputone.ctc.value !== "" && inputone.ctc.value < 0)
              }
              helperText={
                (inputone.ctc.isRequired &&
                  inputone.ctc.value === "" &&
                  "This field is required") ||
                (inputone.ctc.value !== "" &&
                  inputone.ctc.value < 0 &&
                  "Value cannot be less than 0")
              }
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item xs={12}     sx={{ marginTop: 3 }}>
            <Typography variant="body1" sx={{ color: "black" }}>
              Enter your Basic component of the Amount
            </Typography>
            <TextField
            label="Basic Component"
              type="number"
              onChange={handleChangeone}
              name="basic"
              value={inputone.basic.value}
              placeholder="Basic"
              sx={{ marginTop: 1, width: "50%" }}
              error={inputone.basic.value<0}
              helperText={
                
                (inputone.basic.value < 0 && "Value cannot be less than 0")
              }
              inputProps={{ min: 0 }}
            />
          </Grid>

          <Grid item xs={12}     sx={{ marginTop: 3 }}>
            <Typography variant="body1" sx={{ color: "black" }}>
              Enter the 'HRA' Component of your CTC
            </Typography>
            <TextField 
            label="HRA"
              type="number"
              onChange={handleChangeone}
              name="hra"
              value={inputone.hra.value}
              placeholder="HRA"
              sx={{ marginTop: 1, width: "50%" }}
              error={inputone.hra.value<0}
              helperText={
           
                (inputone.hra.value < 0 && "Value cannot be less than 0")
              }
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item xs={12}     sx={{ marginTop: 3 }}>
            <Typography variant="body1" sx={{ color: "black" }}>
              Enter the 'Special allowance' Component of your CTC
            </Typography>
            <TextField
            label="Special Allowance"
              type="number"
              onChange={handleChangeone}
              name="specialAllowance"
              value={inputone.specialAllowance.value}
              placeholder="Special Allowance"
              sx={{ marginTop: 1, width: "50%" }}
              error={inputone.specialAllowance.value<0}
              helperText={
        
                (inputone.specialAllowance.value < 0 &&
                  "Value cannot be less than 0")
              }
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item xs={12}     sx={{ marginTop: 3 }}>
            <Typography variant="body1" sx={{ color: "black" }}>
              Enter the Other allowances in your CTC
            </Typography>
            <TextField
            label="Other Allowance"
              type="number"
              onChange={handleChangeone}
              name="otherAllowance"
              value={inputone.otherAllowance.value}
              placeholder="Other Allowance"
              sx={{ marginTop: 1, width: "50%" }}
              error={inputone.otherAllowance.value <0}
              helperText={
      
                (inputone.specialAllowance.value < 0 &&
                  "Value cannot be less than 0")
              }
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item xs={12}     sx={{ marginTop: 3 }}>
            <Typography variant="body1" sx={{ color: "black" }}>
              Enter the Dearness allowances in your CTC
            </Typography>
            <TextField
           
              label="Dearness Allowance"
              type="number"
              onChange={handleChangeone}
              name="da"
              value={inputone.da.value}
              placeholder="Dearness Allowance"
              sx={{ marginTop: 1, width: "50%" }}
              error={inputone.da.value < 0}
              helperText={
                inputone.da.value < 0 && "Value cannot be less than 0"
              }
              inputProps={{ min: 0 }}
            />
          </Grid>

          <Grid item xs={12}     sx={{ marginTop: 3 }}>
            <Typography variant="body1" sx={{ color: "black" }}>
              Enter any other component of your CTC not specified above
            </Typography>
            <TextField
            label="Other component"
              type="number"
              onChange={handleChangeone}
              name="otherComponent"
              value={inputone.otherComponent.value}
              placeholder="Other Component"
              sx={{ marginTop: 1, width: "50%" }}
              error={inputone.otherComponent.value <0}
              helperText={
         
                (inputone.otherComponent.value < 0 &&
                  "Value cannot be less than 0")
              }
              inputProps={{ min: 0 }}
            />

            <Grid item xs={12}     sx={{ marginTop: 3 }}>
              <Typography variant="body1" sx={{ color: "black" }}>
                Enter the 'EPF' Component of your CTC contributed by yourself
              </Typography>
              <TextField
              label="EPF Self"
                type="number"
                onChange={handleChangeone}
                name="epfself"
                value={inputone.epfself.value}
                placeholder="EPF"
                sx={{ marginTop: 1, width: "50%" }}
                error={inputone.epfself.value<0}
                helperText={
                 
                  (inputone.epfself.value < 0 && "Value cannot be less than 0")
                }
                inputProps={{ min: 0 }}
              />
            </Grid>

            <Grid item xs={12}     sx={{ marginTop: 3 }}>
              <Typography variant="body1" sx={{ color: "black" }}>
                Enter the 'Employer Provident Fund (EPF) ' Component of your CTC
                contributed by your company/employer
              </Typography>
              <TextField
              label="EPF"
                type="number"
                onChange={handleChangeone}
                name="epfcompany"
                value={inputone.epfcompany.value}
                placeholder="EPF"
                sx={{ marginTop: 1, width: "50%" }}
                error={inputone.epfcompany.value < 0}
                helperText={
          
                  (inputone.epfcompany.value < 0 &&
                    "Value cannot be less than 0")
                }
                inputProps={{ min: 0 }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}     sx={{ marginTop: 3 }}>
            <Typography variant="body1" sx={{ color: "black" }}>
              Enter the 'Bonus/Variable pay' received during the year{" "}
            </Typography>
            <TextField
            label="Bonus"
              type="number"
              onChange={handleChangeone}
              name="bonus"
              value={inputone.bonus.value}
              placeholder="Bonus"
              sx={{ marginTop: 1, width: "50%" }}
              error={inputone.bonus.value < 0 }
              helperText={
                (inputone.bonus.value < 0 &&
                  "Value cannot be less than 0")
              }
              inputProps={{ min: 0 }}
            />
          </Grid>

          <Grid item xs={12}     sx={{ marginTop: 3 }}>
            <Typography variant="body1" sx={{ color: "black" }}>
              Enter Short-term capital gains on market investments
            </Typography>
            <TextField
            label="Short term capital gains"
              type="number"
              onChange={handleChangeone}
              name="shortTermCapitalGains"
              value={inputone.shortTermCapitalGains.value}
              placeholder="Short Term Capital Gains"
              sx={{ marginTop: 1, width: "50%" }}
              error={inputone.shortTermCapitalGains.value < 0 }
              helperText={
                (inputone.shortTermCapitalGains.value < 0 &&
                  "Value cannot be less than 0")
              }
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item xs={12}     sx={{ marginTop: 3 }}>
            <Typography variant="body1" sx={{ color: "black" }}>
              Enter Long-term capital gains on market investments
            </Typography>
            <TextField
            label="Long term capital gains"
              type="number"
              onChange={handleChangeone}
              name="longTermCapitalGains"
              value={inputone.longTermCapitalGains.value}
              placeholder="Long Term Capital Gains"
              sx={{ marginTop: 1, width: "50%" }}
              error={inputone.longTermCapitalGains.value < 0 }
              helperText={
                (inputone.longTermCapitalGains.value < 0 &&
                  "Value cannot be less than 0")
              }
              inputProps={{ min: 0 }}
            />
          </Grid>

          <Grid item xs={12}     sx={{ marginTop: 3 }}>
            <Typography variant="body1" sx={{ color: "black" }}>
              Enter income from other sources such as interest earned on bank or
              savings bank deposits
            </Typography>
            <TextField
            label=" Income from other source"
              type="number"
              onChange={handleChangeone}
              name="incomeothersource"
              value={inputone.incomeothersource.value}
              placeholder="Income other sources"
              sx={{ marginTop: 1, width: "50%" }}
              error={inputone.incomeothersource.value<0}
              helperText={
              
                (inputone.incomeothersource.value < 0 &&
                  "Value cannot be less than 0")
              }
              inputProps={{ min: 0 }}
            />
          </Grid>
        </Box>
      </form>
    </Box>
  );
}

export default Form1;
