import React from 'react';
import { Stepper, Step, StepLabel, Typography, Box } from '@mui/material';
import { Person, CheckCircle } from '@mui/icons-material';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ShowChartIcon from '@mui/icons-material/ShowChart';

const SimpleLogoStepper = () => {
  const steps = [
    {
      logo: <Person fontSize="large" sx={{ color: '#FFFFFF' }} />,
      instruction: 'Select a Tax Regime',
    },
    {
      logo: <CurrencyRupeeIcon fontSize="large" sx={{ color: '#FFFFFF' }} />,
      instruction: 'Provide Income Details',
    },
    {
      logo: <ShowChartIcon fontSize="large" sx={{ color: '#FFFFFF' }} />,
      instruction: 'Provide Investment Details',
    },
    {
      logo: <CheckCircle fontSize="large" sx={{ color: '#FFFFFF' }} />,
      instruction: 'Compare Tax Regimes',
    },
  ];

  return (
    <Box
      sx={{
        background: 'linear-gradient(135deg, #4ADEDE 0%, #3C4CAD 50%, #1F2958 100%)',
        padding: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: 500,
          backgroundColor: 'transparent',
          borderRadius: 8,
          padding: 2,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            textAlign: 'center',
            mb: 2,
            color: '#FFFFFF',
            fontWeight: 'bold',
            fontSize: '1.2rem',
          }}
        >
          Select Your Tax Regime in 4 Easy Steps
        </Typography>
        <Stepper activeStep={0} alternativeLabel>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel
                sx={{
                  '& .MuiStepLabel-label': {
                    fontWeight: 'bold',
                    color: '#FFFFFF',
                  },
                }}
                StepIconProps={{
                  sx: { width: 50, height: 50 },
                  icon: step.logo,
                }}
              />
              <Box sx={{ textAlign: 'center' }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: '#FFFFFF',
                    fontSize: '1rem',
                  }}
                >
                  {step.instruction}
                </Typography>
              </Box>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
};

export default SimpleLogoStepper;
