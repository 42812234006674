import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Card,
  CardContent,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";

const SubformInsurance = ({ handleChangeTwo, inputtwo }) => {
  return (
    <Box>
      <Grid item xs={12} sx={{ marginTop: 3 }}>
        <Typography variant="body1" sx={{ color: "black" }}>
          Enter the amount you have paid as premium for health insurance for
          yourself
        </Typography>

        <TextField
          label="Self Health Insurance premium"
          type="number"
          sx={{ marginTop: 1, width: "50%" }}
          placeholder="Total Insurance Contribution for self"
          name="healthInsuranceSelf"
          value={inputtwo.healthInsuranceSelf.value}
          onChange={handleChangeTwo}
          InputLabelProps={{ shrink: true }}
          error={inputtwo.healthInsuranceSelf.value < 0}
          helperText={
            inputtwo.healthInsuranceSelf.value < 0 &&
            "Value cannot be less than 0"
          }
        />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 3 }}>
        <Typography variant="body1" sx={{ color: "black" }}>
          Are you paying health insurance premium for your parents?
        </Typography>
        <FormControl sx={{ m: 1, minWidth: 80 }}>
          <Select
            autoWidth
            label=""
            onChange={handleChangeTwo}
            name="isHealthInsuranceParents"
            value={inputtwo.isHealthInsuranceParents.value}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 3 }}>
        <Typography variant="body1" sx={{ color: "black" }}>
          Are your parents for whom you are paying health insurance above 60
          years
        </Typography>
        <FormControl sx={{ m: 1, minWidth: 80 }}>
          <Select
            autoWidth
            label=""
            onChange={handleChangeTwo}
            name="isHealthInsuranceParentsAbove60"
            value={
              inputtwo.isHealthInsuranceParentsAbove60.value
            }
            disabled={inputtwo.isHealthInsuranceParents.value !== "Yes"}
            error={
              inputtwo.isHealthInsuranceParents.value === "Yes" &&
              !inputtwo.isHealthInsuranceParentsAbove60.value
            }
            helperText={
              inputtwo.isHealthInsuranceParents.value === "Yes" &&
              inputtwo.isHealthInsuranceParentsAbove60.value &&
              "This field is required"
            }
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 3 }}>
        <Typography variant="body1" sx={{ color: "black" }}>
          Contribution to health insurance of parents (if they are below 60
          years)
        </Typography>
        <TextField
          label="Parents Health Insurance below 60"
          type="number"
          sx={{ marginTop: 1, width: "50%" }}
          name="healthInsuranceParentsContributionBelow60"
          placeholder="Parents Health Insurance below 60"
          value={
            inputtwo.isHealthInsuranceParents.value === "Yes" &&
            inputtwo.isHealthInsuranceParentsAbove60.value !== "No"
              ? ""
              : inputtwo.healthInsuranceParentsContributionBelow60.value
          }
          disabled={
            inputtwo.isHealthInsuranceParents.value !== "Yes" ||
            inputtwo.isHealthInsuranceParentsAbove60.value !== "No"
          }
          onChange={handleChangeTwo}
          required
          InputLabelProps={{ shrink: true }}
          error={
            (inputtwo.isHealthInsuranceParentsAbove60.value === "No" &&
              inputtwo.healthInsuranceParentsContributionBelow60.value ===
                "") ||
            inputtwo.totalHouseRentPaid.value < 0
          }
          helperText={
            (inputtwo.isHealthInsuranceParentsAbove60.value === "No" &&
              inputtwo.healthInsuranceParentsContributionBelow60.value === "" &&
              "This field is required") ||
            (inputtwo.healthInsuranceParentsContributionBelow60.value < 0 &&
              "Value cannot be less than 0")
          }
        />
      </Grid>{" "}
      <Grid item xs={12} sx={{ marginTop: 3 }}>
        <Typography variant="body1" sx={{ color: "black" }}>
          Contribution to health insurance of parents (if they are above 60
          years)
        </Typography>
        <TextField
          label="Parents Health Insurance above 60"
          type="number"
          sx={{ marginTop: 1, width: "50%" }}
          name="healthInsuranceParentsContributionAbove60"
          placeholder="Parents Health Insurance Above 60"
          value={
            inputtwo.isHealthInsuranceParents.value === "Yes" &&
            inputtwo.isHealthInsuranceParentsAbove60.value !== "Yes"
              ? ""
              : inputtwo.healthInsuranceParentsContributionAbove60.value
          }
          disabled={
            inputtwo.isHealthInsuranceParents.value != "Yes" ||
            inputtwo.isHealthInsuranceParentsAbove60.value !== "Yes"
          }
          onChange={handleChangeTwo}
          required
          error={
            (inputtwo.isHealthInsuranceParents.value === "Yes" &&
              inputtwo.isHealthInsuranceParentsAbove60.value === "Yes" &&
              inputtwo.healthInsuranceParentsContributionAbove60.value ===
                "") ||
            inputtwo.totalHouseRentPaid.value < 0
          }
          helperText={
            (inputtwo.isHealthInsuranceParents.value === "Yes" &&
              inputtwo.isHealthInsuranceParentsAbove60.value === "Yes" &&
              inputtwo.healthInsuranceParentsContributionAbove60.value === "" &&
              "This field is required") ||
            (inputtwo.totalHouseRentPaid.value < 0 &&
              "Value cannot be less than 0")
          }
        />
      </Grid>{" "}
      <Grid item xs={12} sx={{ marginTop: 3 }}>
        <Typography variant="body1" sx={{ color: "black" }}>
          Enter the amount paid for medical tests on yourself
        </Typography>
        <FormControl sx={{ m: 1, minWidth: 80 }}>
          <TextField
            autoWidth
            label="Medical Test Self"
            onChange={handleChangeTwo}
            name="medicalTestSelf"
            value={inputtwo.medicalTestSelf.value}
            error={inputtwo.medicalTestSelf.value < 0}
            helperText={
              inputtwo.medicalTestSelf.value < 0 &&
              "Value cannot be less than 0"
            }
          ></TextField>
        </FormControl>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 3 }}>
        <Typography variant="body1" sx={{ color: "black" }}>
          Enter the amount paid for medical tests on parents
        </Typography>
        <FormControl sx={{ m: 1, minWidth: 80 }}>
          <TextField
            autoWidth
            label="Medical Test Parents"
            onChange={handleChangeTwo}
            name="medicalTestParents"
            value={inputtwo.medicalTestParents.value}
            error={inputtwo.medicalTestParents.value < 0}
            helperText={
              inputtwo.medicalTestParents.value < 0 &&
              "Value cannot be less than 0"
            }
          ></TextField>
        </FormControl>
      </Grid>
    </Box>
  );
};

export default SubformInsurance;
